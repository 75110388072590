import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import TechTags from "../../components/techTags";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1><a parentName="h1" {...{
        "href": "https://codepeat.com"
      }}>{`codepeat`}</a></h1>
    <h2>{`Summary`}</h2>
    <p>{`Embarking on a journey as and entrepreneur, a friend and I, set out to conquer the tech industry.
However, the reality of the business world soon set in.`}</p>
    <p>{`Our first client was a small business that required a checker for their employees and a program that
could handle payroll efficiently. While not a particularly challenging project, it gave us the
opportunity to explore and experiment with different technologies. And learn how to work with clients.`}</p>
    <p>{`Its really important to understand the problems that they have, make a ton of question and not take
suggestions as must do. It's really interesting to get out of the engineering mindset and think more about
product, business and the customer. This at the end will make you a better engineer.`}</p>
    <TechTags title="Jobs" tags={["Backend Dev", "Product Owner", "Scrum master", "Devops"]} mdxType="TechTags" />
    <h2>{`Challenges`}</h2>
    <p>{`The journey had a lot of challenges and these are some of them. They aren't in order of difficulty`}</p>
    <ul>
      <li parentName="ul">{`GraphQL like librarie for client side queries `}</li>
      <li parentName="ul">{`Managing resources`}</li>
      <li parentName="ul">{`Understanding what the client needed`}</li>
      <li parentName="ul">{`Managing time`}</li>
    </ul>
    <TechTags title="Technologies" tags={["Python", "Docker", "Vue", "Heroku", "Netlify", "Gatsby", "Twillio"]} mdxType="TechTags" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      